import * as React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"

export default function Page(props) {

  const content = props.data.allContentfulBlogPost.edges.find(edge => {return edge.node.id === props.params.id});
  return (
    <main>
      <title>Dita Basu - {content.node.title ? content.node.title : ""}</title>
       <Layout page="blogpost" content={content}></Layout>
    </main>
  );
}

export const query= graphql` 
    query    {
        allContentfulBlogPost{
          edges{
            node{
              id
              body {
                childMarkdownRemark {
                  html
                }
              }
              description {
                description
              }
              title
              publishDate(formatString: "MMMM Do, YYYY")
            }
            next {
              title
              id
            }
            previous {
              title
              id
            }
          }
        }
    }
`
                    